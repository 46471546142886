// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../Link/Link.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIconCss from "./BannerProviderIconCss.res.js";

function BannerProviderIcon(props) {
  return JsxRuntime.jsx("img", {
              className: BannerProviderIconCss.icon,
              alt: props.alt,
              src: props.src
            });
}

function BannerProviderIcon$AsLink(props) {
  return JsxRuntime.jsx(Link.make, {
              href: props.href,
              children: JsxRuntime.jsx("img", {
                    className: BannerProviderIconCss.icon,
                    alt: props.alt,
                    src: props.src
                  })
            });
}

var AsLink = {
  make: BannerProviderIcon$AsLink
};

var Css;

var make = BannerProviderIcon;

export {
  Css ,
  make ,
  AsLink ,
}
/* Link Not a pure module */
