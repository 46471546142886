// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HorizontalInfiniteScrollCss from "./HorizontalInfiniteScrollCss.res.js";

function HorizontalInfiniteScroll(props) {
  var container = React.useRef(null);
  var icons = React.useRef(null);
  React.useEffect((function () {
          var wrapper = container.current;
          var iconsContainer = icons.current;
          if (!(wrapper == null) && !(iconsContainer == null)) {
            var clone = iconsContainer.cloneNode(true);
            wrapper.appendChild(clone);
          }
          
        }), []);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: props.children,
                          ref: Caml_option.some(icons),
                          className: HorizontalInfiniteScrollCss.childrenContainer
                        }),
                    ref: Caml_option.some(container),
                    className: HorizontalInfiniteScrollCss.animationContainer
                  }),
              className: Cx.cx([HorizontalInfiniteScrollCss.wrapper])
            });
}

var Css;

var make = HorizontalInfiniteScroll;

export {
  Css ,
  make ,
}
/* react Not a pure module */
