// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var gradientMask = Cx.cx([
      "[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]",
      "[-webkit-mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]"
    ]);

var wrapper = Cx.cx([
      "flex",
      "lg:w-8/12",
      "md:w-10/12",
      "w-full",
      "flex-nowrap",
      "overflow-hidden",
      "mx-auto",
      gradientMask
    ]);

var animationContainer = Cx.cx([
      "flex",
      "flex-row",
      "justify-center",
      "items-center",
      "w-max",
      "gap-8",
      "will-change-transform",
      "animate-infinite-scroll"
    ]);

var childrenContainer = Cx.cx([
      "flex",
      "items-center",
      "justify-center",
      "gap-8"
    ]);

export {
  gradientMask ,
  wrapper ,
  animationContainer ,
  childrenContainer ,
}
/* gradientMask Not a pure module */
