// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcon from "./components/BannerProviderIcon.res.js";
import * as HorizontalInfiniteScroll from "../HorizontalInfiniteScroll/HorizontalInfiniteScroll.res.js";
import LogoH5Png from "Images/companies/logo-h5.png";
import LogoIBMPng from "Images/companies/logo-IBM.png";
import LogoNTTPng from "Images/companies/logo-NTT.png";
import LogoAwsSvg from "Images/companies/logo-aws.svg";
import LogoQtsPng from "Images/companies/logo-qts.png";
import LogoEdgePng from "Images/companies/logo-edge.png";
import LogoEnzuPng from "Images/companies/logo-enzu.png";
import LogoAzureSvg from "Images/companies/logo-azure.svg";
import LogoLumenPng from "Images/companies/logo-lumen.png";
import LogoGoogleSvg from "Images/companies/logo-google.svg";
import LogoUcloudPng from "Images/companies/logo-ucloud.png";
import LogoAlibabaPng from "Images/companies/logo-alibaba.png";
import LogoCologixPng from "Images/companies/logo-cologix.png";
import LogoEquinixPng from "Images/companies/logo-equinix.png";
import LogoTencentPng from "Images/companies/logo-tencent.png";
import LogoCoresitePng from "Images/companies/logo-coresite.png";
import LogoDatabankPng from "Images/companies/logo-databank.png";
import LogoEvocativePng from "Images/companies/logo-evocative.png";
import LogoTierpointPng from "Images/companies/logo-tierpoint.png";
import LogoFlexentialPng from "Images/companies/logo-flexential.png";
import LogoCentersquarePng from "Images/companies/logo-centersquare.png";
import LogoIronmountainPng from "Images/companies/logo-ironmountain.png";
import VerticalPng from "Images/companies/zenlayer/vertical.png";
import LogoControlPlaneSvg from "Images/companies/logo-control-plane.svg";
import LogoDigitalRealtyPng from "Images/companies/logo-digital-realty.png";
import LogoDatacentersComSvg from "Images/companies/logo-datacenters.com.svg";
import HivelocityPng from "Images/companies/hivelocity/hivelocity.png";

function iconToComponent(icon) {
  switch (icon) {
    case "Alibaba" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoAlibabaPng,
                    alt: "Alibaba"
                  });
    case "AWS" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoAwsSvg,
                    alt: "AWS",
                    href: "/providers/amazon-aws"
                  });
    case "Azure" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoAzureSvg,
                    alt: "Microsoft Azure",
                    href: "/providers/microsoft-azure"
                  });
    case "Centersquare" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoCentersquarePng,
                    alt: "Centersquare",
                    href: "/providers/centersquare"
                  });
    case "ControlPlane" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoControlPlaneSvg,
                    alt: "Control Plane",
                    href: Routes_Page.controlPlaneSignUp
                  });
    case "Coresite" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoCoresitePng,
                    alt: "Coresite",
                    href: "/providers/coresite"
                  });
    case "Datacenters" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoDatacentersComSvg,
                    alt: "Datacenters.com",
                    href: "/"
                  });
    case "DigitalRealty" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoDigitalRealtyPng,
                    alt: "Digital Realty",
                    href: "/providers/digital-realty"
                  });
    case "Equinix" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoEquinixPng,
                    alt: "Equinix",
                    href: "/providers/equinix"
                  });
    case "Evocative" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoEvocativePng,
                    alt: "Evocative",
                    href: "/providers/evocative"
                  });
    case "Flexential" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoFlexentialPng,
                    alt: "Flexential",
                    href: "/providers/flexential"
                  });
    case "Google" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoGoogleSvg,
                    alt: "Google",
                    href: "/providers/google"
                  });
    case "H5" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoH5Png,
                    alt: "H5 Data Centers",
                    href: "/providers/h5-data-centers"
                  });
    case "Qts" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: LogoQtsPng,
                    alt: "Qts",
                    href: "/providers/qts"
                  });
    case "Tencent" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoTencentPng,
                    alt: "Tencent"
                  });
    case "Ucloud" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoUcloudPng,
                    alt: "Ucloud"
                  });
    case "Zenlayer" :
        return JsxRuntime.jsx(BannerProviderIcon.AsLink.make, {
                    src: VerticalPng,
                    alt: "Zenlayer",
                    href: "/providers/zenlayer"
                  });
    case "Hivelocity" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: HivelocityPng,
                    alt: "Hivelocity"
                  });
    case "Lumen" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoLumenPng,
                    alt: "Lumen"
                  });
    case "Databank" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoDatabankPng,
                    alt: "Databank"
                  });
    case "NTT" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoNTTPng,
                    alt: "NTT"
                  });
    case "IBM" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoIBMPng,
                    alt: "IBM"
                  });
    case "EdgeConnex" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoEdgePng,
                    alt: "EdgeConnex"
                  });
    case "Enzu" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoEnzuPng,
                    alt: "Enzu"
                  });
    case "Cologix" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoCologixPng,
                    alt: "Cologix"
                  });
    case "Tierpoint" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoTierpointPng,
                    alt: "Tierpoint"
                  });
    case "Ironmountain" :
        return JsxRuntime.jsx(BannerProviderIcon.make, {
                    src: LogoIronmountainPng,
                    alt: "Ironmountain"
                  });
    
  }
}

function BannerProviderIcons(props) {
  var tmp;
  switch (props.context) {
    case "BareMetal" :
        tmp = JsxRuntime.jsxs(HorizontalInfiniteScroll.make, {
              children: [
                iconToComponent("Zenlayer"),
                iconToComponent("AWS"),
                iconToComponent("Google"),
                iconToComponent("Azure"),
                iconToComponent("Alibaba"),
                iconToComponent("Ucloud"),
                iconToComponent("Tencent")
              ]
            });
        break;
    case "Colocation" :
        tmp = JsxRuntime.jsxs(HorizontalInfiniteScroll.make, {
              children: [
                iconToComponent("Qts"),
                iconToComponent("Coresite"),
                iconToComponent("Centersquare"),
                iconToComponent("Flexential"),
                iconToComponent("DigitalRealty"),
                iconToComponent("Equinix"),
                iconToComponent("H5")
              ]
            });
        break;
    case "Multicloud" :
        tmp = JsxRuntime.jsxs(HorizontalInfiniteScroll.make, {
              children: [
                iconToComponent("AWS"),
                iconToComponent("Google"),
                iconToComponent("Azure"),
                iconToComponent("ControlPlane"),
                iconToComponent("Datacenters")
              ]
            });
        break;
    case "Homepage" :
        tmp = JsxRuntime.jsxs(HorizontalInfiniteScroll.make, {
              children: [
                iconToComponent("Equinix"),
                iconToComponent("DigitalRealty"),
                iconToComponent("Zenlayer"),
                iconToComponent("AWS"),
                iconToComponent("Lumen"),
                iconToComponent("Databank"),
                iconToComponent("NTT"),
                iconToComponent("IBM"),
                iconToComponent("Hivelocity"),
                iconToComponent("EdgeConnex"),
                iconToComponent("Enzu"),
                iconToComponent("Cologix"),
                iconToComponent("Flexential"),
                iconToComponent("Azure"),
                iconToComponent("Centersquare"),
                iconToComponent("Tierpoint"),
                iconToComponent("Qts"),
                iconToComponent("Ironmountain"),
                iconToComponent("Coresite"),
                iconToComponent("Google")
              ]
            });
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp
            });
}

var make = BannerProviderIcons;

export {
  iconToComponent ,
  make ,
}
/* react/jsx-runtime Not a pure module */
