// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var icon = Cx.cx([
      "h-auto",
      "max-h-10",
      "max-w-28"
    ]);

export {
  icon ,
}
/* icon Not a pure module */
